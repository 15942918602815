import isArray from 'lodash/isArray'
import { SubmitValues } from 'components/ItemModal/itemModalTypes'
import { CartItemInput } from 'types/graphql-global-types.d'

import { itemQuestionsFragment_locationItemQuestions as locationItemQuestionsType } from './types/itemQuestionsFragment'

export default function getCartItemInput({
  questions,
  values,
  id,
  omitInstruction = false
}: {
  questions: locationItemQuestionsType[]
  values: SubmitValues
  id: number
  omitInstruction?: boolean
}): CartItemInput {
  const activeQuestions = questions.filter(
    (q: locationItemQuestionsType) => q.status === 'active'
  )
  const questionsInput = activeQuestions.map((q) => {
    const key = `question-${q.id}`
    const formAnswer = values[key]
    let answerIds: number[]
    if (isArray(formAnswer)) {
      answerIds = formAnswer
    } else if (formAnswer) {
      answerIds = [formAnswer as number]
    } else {
      answerIds = []
    }
    return {
      id: q.id,
      answerIds
    }
  })
  return {
    locationItemId: id,
    quantity: values.quantity!,
    specialInstructions: omitInstruction ? '' : values.specialInstructions,
    questions: questionsInput
  }
}
