import { gql } from '@apollo/client'

export default gql`
  query cartItemEstimation($item: CartItemInput!) {
    cartItemEstimation(item: $item) {
      total {
        cents
        formatted
      }
      taxIncluded
    }
  }
`
