import React, { useContext } from 'react'
import QuantityInputGroup from 'components/ui/QuantityInputGroup'
import Button from 'components/ui/Button'
import { useQuery } from '@apollo/client'
import {
  cartItemEstimation,
  cartItemEstimationVariables
} from 'components/ItemModal/types/cartItemEstimation'
import ITEM_PRICE_QUERY from 'components/ItemModal/itemPriceQuery'
import { CartItemInput } from 'types/graphql-global-types'
import { itemQuestionsFragment_item as itemType } from 'components/ItemModal/types/itemQuestionsFragment'
import Loading from 'components/Loading'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'

type Props = {
  item: itemType
  itemInput: CartItemInput
  buttonText: string
  forUpdate: boolean
  submitting: boolean
  isDelete: boolean
  canOrder: boolean
}

const BaseItemModalBottom = ({
  item,
  forUpdate,
  canOrder,
  buttonText,
  submitting,
  itemInput,
  isDelete
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)
  const { data, loading } = useQuery<
    cartItemEstimation,
    cartItemEstimationVariables
  >(ITEM_PRICE_QUERY, {
    fetchPolicy: 'cache-first',
    variables: {
      item: itemInput
    }
  })

  return (
    <div
      className={cn(
        'sticky bottom-0 left-0 right-0 p-5 border-t border-solid',
        {
          'bg-albertsons-neutral7 border-albertsons-neutral6': albertsons,
          'bg-gray-50 border-gray-100': !albertsons
        }
      )}
    >
      <div className="flex justify-between">
        <p className="font-bold">{item.name}</p>
        <div className="flex flex-col items-end">
          {data?.cartItemEstimation && !loading ? (
            <p>{data.cartItemEstimation.total.formatted}</p>
          ) : (
            <Loading size="small" text="" />
          )}
          {data?.cartItemEstimation?.taxIncluded && (
            <p className="text-sm text-gray-500">*Tax Included</p>
          )}
        </div>
      </div>
      <div className="flex justify-between mt-2">
        <div className="w-1/3">
          <QuantityInputGroup
            albertsons={albertsons}
            hasGroup={false}
            itemName={item.name}
            maxValue={9}
            minValue={forUpdate ? 0 : 1}
            name="quantity"
          />
        </div>
        <div className="w-2/3 pl-5">
          <Button
            color={isDelete ? 'danger' : 'primary'}
            disabled={submitting || !canOrder}
            type="submit"
            variant="contained"
            fullWidth
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default BaseItemModalBottom
