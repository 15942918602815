/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import CheckBoxGroup, {
  Option,
  PossibleValue
} from 'components/ui/CheckBoxGroup'

type CheckBoxGroupProps<T extends PossibleValue> = {
  description?: string
  label: string
  options: Option<T>[]
  maxOptions?: number | null
  questionId: number
}

export type Props<T extends PossibleValue> = CheckBoxGroupProps<T> & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
}

function InnerFormCheckBoxGroup<T extends PossibleValue>({
  description,
  label,
  options,
  input,
  name,
  maxOptions,
  questionId,
  meta: { touched, error: syncError, submitError }
}: FieldRenderProps<T[], HTMLInputElement> & CheckBoxGroupProps<T>) {
  const error = syncError || submitError
  const showError = (touched || submitError) && !!error

  const makeOnChange = (id: T) => {
    let newValue = []
    if (input.value !== []) {
      if (input.value.includes(id)) {
        newValue = input.value.filter((a) => a !== id)
      } else if (!maxOptions || maxOptions > input.value.length) {
        newValue = [...input.value, id]
      } else {
        newValue = [...input.value]
      }
    } else {
      newValue = [id]
    }

    input.onChange(newValue)
    input.onBlur()
  }

  return (
    <CheckBoxGroup<T>
      description={description}
      error={showError ? error : null}
      label={label}
      name={name}
      onChange={makeOnChange}
      options={options}
      questionId={questionId}
      value={input.value}
    />
  )
}

export default function FormCheckBoxGroup<T extends PossibleValue = string>({
  name,
  fieldProps = {},
  ...innerProps
}: Props<T>) {
  return (
    <Field<T[], FieldRenderProps<T[], HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => (
        <InnerFormCheckBoxGroup<T> {...props} {...innerProps} name={name} />
      )}
      {...fieldProps}
    />
  )
}
