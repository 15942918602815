import isArray from 'lodash/isArray'
import { SubmitErrors, SubmitValues } from 'components/ItemModal/itemModalTypes'

import { FormQuestions } from './ItemModalQuestions'

const makeValidate = (questions: FormQuestions) => (
  values: SubmitValues
): SubmitErrors => {
  const errors: SubmitErrors = {}

  if (values.quantity === 0) return errors

  questions
    .filter((q) => q.required)
    .forEach((q) => {
      const key = `question-${q.id}`
      const value = values[key]
      if (isArray(value)) {
        if (value.length === 0) {
          errors[key] = 'Required'
        }
      } else if (!value) {
        errors[key] = 'Required'
      }
    })
  return errors
}

export default makeValidate
