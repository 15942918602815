import React from 'react'

export type Props = {
  children: JSX.Element | string
  className?: string
  meta?: React.ReactNode
} & React.HTMLAttributes<HTMLDivElement>
const PageError = ({ children, className, meta, ...rest }: Props) => (
  <div
    className={`flex items-center justify-center my-10 min-h-full ${className}`}
    {...rest}
  >
    {meta}
    <svg
      className="w-8 h-8 mr-3 text-orange-500"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
        fillRule="evenodd"
      />
    </svg>
    <p className="text-lg leading-6 font-medium text-gray-400">{children}</p>
  </div>
)

export default PageError
