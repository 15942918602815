import React from 'react'
import { gql } from '@apollo/client'
import FormRadioGroup from 'components/ui/FormRadioGroup'
import FormCheckBoxGroup from 'components/ui/FormCheckBoxGroup'

import { itemQuestionsFragment_locationItemQuestions as locationItemQuestionsType } from './types/itemQuestionsFragment'

export const itemQuestionsFragment = gql`
  fragment itemQuestionsFragment on LocationItem {
    id
    item {
      id
      name
      price {
        formatted
        cents
      }
      hasSpecialInstructions
      image {
        medium
        high
      }
      description
    }
    locationItemQuestions(status: active) {
      id
      status
      text
      itemQuestion {
        id
        text
        questionType
        questionTypeId
        required
        maxAnswers
      }
      locationItemAnswers(status: active) {
        id
        status
        itemAnswer {
          id
          active
          text
          default
          adjustment {
            cents
            formatted
          }
        }
      }
    }
  }
`

export type FormQuestions = {
  id: number
  text: string
  questionType: 'checkbox' | 'dropdown' | null
  required: boolean | null
  maxAnswers: number | null
  itemAnswers: {
    id: number | null
    text: string | null
    default: boolean
    adjustment: {
      __typename: 'Money'
      cents: number
      formatted: string
    } | null
  }[]
}[]

type Props = {
  questions: FormQuestions
}

type QuestionForOptions = {
  itemAnswers:
    | {
        id: number | null
        text: string | null
        adjustment: {
          __typename: 'Money'
          cents: number
          formatted: string
        } | null
      }[]
    | null
}

export const locationQuestionsToQuestionsAdapter = (
  locationQuestions: locationItemQuestionsType[]
): FormQuestions => {
  return locationQuestions
    .filter((lq) => lq.status === 'active')
    .map((lq) => ({
      id: lq.id,
      text: lq.itemQuestion.text,
      questionType: lq.itemQuestion.questionType,
      required: lq.itemQuestion.required,
      maxAnswers: lq.itemQuestion.maxAnswers,
      itemAnswers:
        lq.locationItemAnswers
          ?.filter((la) => la.status === 'active')
          .map((la) => ({
            id: la.id,
            text: la.itemAnswer.text,
            default: la.itemAnswer.default,
            adjustment: la.itemAnswer.adjustment
          })) ?? []
    }))
}

const ItemModalQuestions = ({ questions }: Props) => {
  const getOptions = (question: QuestionForOptions) => {
    return question.itemAnswers!.map((a) => ({
      label: `${a.text}${
        a.adjustment?.cents && a.adjustment?.cents > 0
          ? ` (+${a.adjustment?.formatted})`
          : ''
      }`,
      value: a.id!
    }))
  }

  return (
    <>
      {questions.map((question) => {
        let label = question.text
        const extraLabels = []
        if (question.required) {
          extraLabels.push('required')
        }
        if (question.maxAnswers && question.maxAnswers > 0) {
          extraLabels.push(`up to ${question.maxAnswers}`)
        }
        if (extraLabels.length > 0) {
          label += ` (${extraLabels.join(', ')})`
        }
        return (
          <div key={question.id} className="my-5">
            {question.questionType === 'checkbox' && (
              <FormCheckBoxGroup
                label={label}
                maxOptions={
                  question.maxAnswers && question.maxAnswers > 0
                    ? question.maxAnswers
                    : null
                }
                name={`question-${question.id}`}
                options={getOptions(question)}
                questionId={question.id}
              />
            )}
            {question.questionType === 'dropdown' && (
              <FormRadioGroup
                label={label}
                name={`question-${question.id}`}
                options={getOptions(question)}
                questionId={question.id}
              />
            )}
          </div>
        )
      })}
    </>
  )
}

export default ItemModalQuestions
