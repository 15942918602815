import React from 'react'
import { gql } from '@apollo/client'
import Image from 'components/Image'
import NullsToUndefined from 'utils/NullsToUndefined'

import { itemTagFragment as itemTagFragmentType } from './types/itemTagFragment'

type Props = {
  tag: NullsToUndefined<itemTagFragmentType>
  hideName?: boolean
}

export const itemTagFragment = gql`
  fragment itemTagFragment on ItemTag {
    id
    name
    imageSvgUrl
    highlighted
  }
`

const Tag = ({ tag, hideName = false }: Props) => (
  <div className="flex bg-white m-0.5 rounded-full items-center border-solid border border-gray-100">
    {tag.imageSvgUrl && (
      <Image alt={tag.name} height={24} src={tag.imageSvgUrl} width={24} />
    )}
    {!hideName && <p className="text-xs pl-1 pr-2 text-gray-500">{tag.name}</p>}
  </div>
)

export default Tag
