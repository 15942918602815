import React from 'react'
import { Field, FieldRenderProps } from 'react-final-form'
import cn from 'classnames'
import { MinusIcon, PlusIcon } from '@heroicons/react/solid'

type Props = {
  name: string
  onChange?: (value: number) => void
  minValue: number | null
  maxValue: number | null
  albertsons?: boolean
  itemName: string
  hasGroup?: boolean
}

class InnerQuantityInputGroup extends React.Component<
  FieldRenderProps<number, HTMLInputElement> & Props
> {
  render() {
    const {
      input,
      description,
      minValue = null,
      maxValue = null,
      albertsons = false,
      hasGroup = true,
      itemName,
      meta: { touched, error: syncError, submitError }
    } = this.props
    const error = syncError || submitError
    const showError = (touched || submitError) && !!error
    const prevDisabled = minValue !== null && input.value === minValue
    const nextDisabled = maxValue !== null && input.value === maxValue

    const onPrevButtonClick = () => {
      if (prevDisabled) return
      if (input.value > 0) {
        input.onChange(input.value - 1)
      }
    }

    const onNextButtonClick = () => {
      if (nextDisabled) return
      input.onChange(input.value + 1)
    }

    return (
      <>
        <div
          className={cn(
            'border border-solid border-gray-300 flex justify-between items-center px-2 h-10',
            {
              'ring-red-500 border-red-500': showError,
              'rounded-2xl': !albertsons,
              'rounded-btn-albertsons': albertsons
            }
          )}
        >
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            aria-label={`Decrease quantity ${itemName}`}
            className={cn('w-1/3 flex justify-center outline-none-mouse', {
              'cursor-auto': prevDisabled
            })}
            onClick={onPrevButtonClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') onPrevButtonClick()
            }}
            role="button"
            tabIndex={0}
          >
            <MinusIcon
              className={cn('w-5 h-5', {
                'text-gray-700': !prevDisabled,
                'text-gray-300': prevDisabled
              })}
            />
          </div>

          <p
            aria-label={`${input.value} ${
              input.value === 1 ? 'unit' : 'units'
            } of ${itemName}`}
            aria-live="assertive"
            className="w-1/3 text-center"
          >
            {input.value}
          </p>

          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
          <div
            aria-label={`Increase quantity ${itemName}`}
            className={cn('w-1/3 flex justify-center outline-none-mouse', {
              'cursor-auto': nextDisabled
            })}
            onClick={onNextButtonClick}
            onKeyDown={(e) => {
              if (e.key === 'Enter') onNextButtonClick()
            }}
            role="button"
            tabIndex={0}
          >
            <PlusIcon
              className={cn('w-5 h-5', {
                'text-gray-700': !nextDisabled,
                'text-gray-300': nextDisabled
              })}
            />
          </div>
        </div>

        {(showError || description) && (
          <div>
            {!showError && description && <p className="p-5">{description}</p>}
            {showError && !hasGroup && <p className="text-red-500">{error}</p>}
          </div>
        )}
      </>
    )
  }
}

export default function QuantityInputGroup({
  name,
  minValue,
  maxValue,
  albertsons,
  hasGroup,
  ...innerProps
}: Props) {
  return (
    <Field<number, FieldRenderProps<number, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => (
        <InnerQuantityInputGroup
          {...props}
          {...innerProps}
          albertsons={albertsons}
          hasGroup={hasGroup}
          maxValue={maxValue}
          minValue={minValue}
          name={name}
        />
      )}
    />
  )
}
