/* eslint-disable react/destructuring-assignment */

import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'
import RadioGroup, { Option, PossibleValue } from 'components/ui/RadioGroup'

type RadioGroupProps<T extends PossibleValue> = {
  description?: string
  label: string
  options: Option<T>[]
  columns?: number
  questionId?: number
}

export type Props<T extends PossibleValue> = RadioGroupProps<T> & {
  name: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  fieldProps?: Partial<FieldProps<any, any>>
  validate?: (value: T) => string | undefined
  error?: string
}

function InnerFormRadioGroup<T extends PossibleValue>({
  description,
  label,
  options,
  input,
  name,
  columns,
  questionId,
  error: manualError,
  meta: { touched, error: syncError, submitError }
}: FieldRenderProps<T, HTMLInputElement> & RadioGroupProps<T>) {
  const error = syncError || submitError || manualError
  const showError = (touched || submitError) && (!!error || !!manualError)
  return (
    <RadioGroup<T>
      columns={columns}
      description={description}
      error={showError ? error : null}
      label={label}
      name={name}
      onChange={(value) => {
        input.onChange(value)
        input.onBlur()
      }}
      options={options}
      questionId={questionId}
      value={input.value}
    />
  )
}

export default function FormRadioGroup<T extends PossibleValue = string>({
  name,
  validate,
  error,
  fieldProps = {},
  ...innerProps
}: Props<T>) {
  return (
    <Field<T, FieldRenderProps<T, HTMLInputElement>, HTMLInputElement>
      name={name}
      render={(props) => (
        <InnerFormRadioGroup<T>
          {...props}
          {...innerProps}
          error={error}
          name={name}
        />
      )}
      validate={validate}
      {...fieldProps}
    />
  )
}
