import Modal from 'react-modal'
import React, { useContext, useMemo } from 'react'
import ItemModalQuestions, {
  locationQuestionsToQuestionsAdapter
} from 'components/ItemModal/ItemModalQuestions'
import Image from 'components/Image'
import focusOnError from 'components/ui/focusOnError'
import { Form } from 'react-final-form'
import FormTextField from 'components/ui/FormTextField'
import { SubmitValues } from 'components/ItemModal/itemModalTypes'
import makeValidate from 'components/ItemModal/makeValidate'
import { locationItemQuery_locationItem as locationItemType } from 'components/ItemModal/types/locationItemQuery'
import FormError from 'components/ui/FormError'
import getCartItemInput from 'components/ItemModal/getCartItemInput'
import BaseItemModalBottom from 'components/ItemModal/BaseItemModalBottom'
import Tag from 'components/Tag'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'
import cn from 'classnames'
import { PlusIcon } from '@heroicons/react/solid'

import { itemQuestionsFragment_locationItemQuestions as locationItemQuestionsType } from './types/itemQuestionsFragment'

export const itemModalCustomStyles = {
  overlay: {
    backgroundColor: 'rgba(0,0,0,0.53)',
    zIndex: 100
  },
  content: {
    top: '50%',
    left: '50%',
    right: '-50%',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    overflow: 'auto',
    maxHeight: '80%',
    padding: '0',
    border: 'none',
    maxWidth: '355px'
  }
}

function getButtonText(forUpdate: boolean, quantity: number | null) {
  if (forUpdate) {
    return quantity === 0 ? 'Remove' : 'Update'
  }
  return 'Add'
}

export const CloseModalIcon = ({ closeModal }: { closeModal: () => void }) => {
  return (
    <div className="fixed top-2 right-2 z-30">
      <div
        aria-label="Close button"
        className="bg-red bg-white opacity-70 rounded-full outline-none-mouse"
        onClick={closeModal}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            closeModal()
          }
        }}
        role="button"
        tabIndex={0}
      >
        <PlusIcon className="rotate-45 w-9 h-9 text-gray-500" />
      </div>
    </div>
  )
}

type Props = {
  modalIsOpen: boolean
  closeModal: () => void
  initialValues: SubmitValues
  onSubmit: (values: SubmitValues) => void
  locationItem: locationItemType
  forUpdate?: boolean
  notice?: React.ReactElement
  canOrder?: boolean
}

const BaseItemModal = ({
  modalIsOpen,
  closeModal,
  onSubmit,
  initialValues,
  locationItem,
  forUpdate = false,
  notice,
  canOrder = true
}: Props) => {
  const { albertsons } = useContext(ShiftPageContext)

  const questions = useMemo(
    () =>
      locationItem.locationItemQuestions.filter(
        (q: locationItemQuestionsType) => q.status === 'active'
      ),
    [locationItem.locationItemQuestions]
  )
  const { item } = locationItem

  const formQuestions = locationQuestionsToQuestionsAdapter(
    locationItem.locationItemQuestions
  )

  const validate = makeValidate(formQuestions)

  return (
    <Modal
      contentLabel="add item"
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      style={itemModalCustomStyles}
    >
      <div
        className={cn({
          albertsons
        })}
      >
        <CloseModalIcon closeModal={closeModal} />

        <Form<SubmitValues>
          decorators={[focusOnError]}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validate={validate}
        >
          {({ handleSubmit, values, submitting }) => (
            <form onSubmit={handleSubmit}>
              <div className="w-full shadow-lg box-border">
                <div className="flex justify-center relative">
                  <Image
                    alt={item.name}
                    className="rounded-t"
                    height={355}
                    src={item.image.high}
                    width={355}
                  />
                  <div className="absolute bottom-1 left-4 right-4 z-30 flex flex-wrap">
                    {item.tags.map((tag) => (
                      <Tag key={tag.id} tag={tag} />
                    ))}
                  </div>
                </div>
                {notice}

                <FormError className="mx-4 mt-4 mb-2" />
                <div className="p-4 space-y-4">
                  <div>{item.description}</div>

                  <ItemModalQuestions questions={formQuestions} />

                  {item.hasSpecialInstructions && (
                    <FormTextField
                      label="Special Instructions"
                      name="specialInstructions"
                    />
                  )}
                </div>
                <BaseItemModalBottom
                  buttonText={getButtonText(forUpdate, values.quantity)}
                  canOrder={canOrder}
                  forUpdate={forUpdate}
                  isDelete={forUpdate ? values.quantity === 0 : false}
                  item={item}
                  itemInput={getCartItemInput({
                    questions,
                    values,
                    id: locationItem.id,
                    omitInstruction: true
                  })}
                  submitting={submitting}
                />
              </div>
            </form>
          )}
        </Form>
      </div>
    </Modal>
  )
}

export default BaseItemModal
