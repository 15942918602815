/* eslint-disable react/no-array-index-key */
import React, { useContext } from 'react'
import cn from 'classnames'
import isEqual from 'lodash/isEqual'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { ShiftPageContext } from 'components/pages/ShiftPage/ShiftPageProvider'

export type PossibleValue = string | number | object | boolean

export type Option<T extends PossibleValue> = {
  label: React.ReactElement | string
  value: T
  description?: string | JSX.Element
}

export type Props<T extends PossibleValue = string> = {
  label?: string
  description?: string
  name: string
  options: Option<T>[]
  value: T | null
  onChange: (value: T) => void
  error?: boolean
  columns?: number
  questionId?: number
}

export default function RadioGroup<T extends PossibleValue = string>({
  label,
  description,
  error,
  options,
  value,
  name,
  onChange,
  columns,
  questionId
}: Props<T>) {
  const { albertsons } = useContext(ShiftPageContext)
  return (
    <div
      aria-describedby={`description-${questionId}`}
      aria-labelledby={`label-${questionId}`}
      className="w-full relative"
      role="radiogroup"
    >
      {error && (
        <div className="absolute inset-y-0 right-0 top-0 pr-3 pointer-events-none">
          <ExclamationCircleIcon
            aria-hidden="true"
            className="h-5 w-5 text-red-500"
          />
        </div>
      )}
      <p
        className="block text-base font-medium text-gray-700 mb-2 pr-2"
        id={`label-${questionId}`}
      >
        {label}
      </p>
      {description && (
        <div>
          <p className="text-sm italic text-gray-500 font-light mt-1">
            {description}
          </p>
        </div>
      )}
      {error && (
        <div>
          <p className="text-sm italic text-red-500 font-light mt-1">{error}</p>
        </div>
      )}
      <div style={{ columnCount: columns }}>
        {options.map((option, index) => (
          <div
            key={`${option.label.toString()}-${option.value.toString()}-${option.value.toString()}-${index}`}
            className="flex items-center"
          >
            <label className="inline-flex items-center mt-2">
              <input
                aria-checked={isEqual(option.value, value)}
                checked={isEqual(option.value, value)}
                className={cn(
                  'rounded-full border-gray-300 shadow-sm focus:ring focus:ring-opacity-50 scroll-offset',
                  {
                    'text-orange-500 focus:border-orange-500 focus:ring-orange-500': !albertsons,
                    'text-albertsons-secondary1 focus:border-albertsons-secondary1 focus:ring-albertsons-secondary1': albertsons
                  }
                )}
                name={name}
                onChange={() => onChange(option.value)}
                type="radio"
              />
              <span className="ml-3 text-sm text-gray-700">{option.label}</span>
            </label>
            {option.description && (
              <span className="mt-2">{option.description}</span>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}
